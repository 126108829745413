import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import heroStyles from '../components/hero.module.css'
import styles from './exhibition.module.css'

import { renderRichText } from "gatsby-source-contentful/rich-text"
import {options} from "./renderer";

class CreationTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulWerk')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} showNavigation={true} path={[
        {url: '/werk', text: 'Werke'},
        {url: `/werk/${post.type.toLowerCase()}`, text: post.type},
        {text: post.title}
      ]}>
        <Helmet title={siteTitle} />
        <h1 className={`${styles.title} with-padding-on-mobile`}>{`${post.title}`}</h1>
        <div className={`${styles.abstract} with-padding-on-mobile`}>
          { post.abstract &&
          <div className={styles.abstractContent}>
            {renderRichText(post.abstract, options)}
          </div>
          }
        </div>
        <div className={heroStyles.hero}>
          <Img
            className={heroStyles.heroImage}
            alt={post.title}
            fluid={post.heroImage.fluid}
            imgStyle={{
              objectFit: "contain"
            }}
          />
          <div className={styles.caption}>{post.heroImage.description}</div>
        </div>
        { post.description &&
          <div className='with-padding-on-mobile'>{renderRichText(post.description, options)}</div>
        }
      </Layout>
    )
  }
}

export default CreationTemplate

export const pageQuery = graphql`
  query Werk($slug: String!, $type: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulWerk(slug: {eq: $slug}, type: {eq: $type}) {
      abstract {
        raw
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            description
              __typename
              fluid(maxWidth: 1180, background: "rgb:000000") {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
      }
      slug
      title
      titleShort
      type
      heroImage {
      description
      fluid(maxWidth: 1180, background: "rgb:000000") {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
  }
`
